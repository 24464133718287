<template>
  <div id="dashboard-admin">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="columns" font-scale="1.5" variant="primary"></b-icon>
        {{ $t('pages.dashboard.title') }}
      </h3>
    </div>

    <hr class="mb-2" />

    <div class="counter-options mb-4">
      <div class="card">
        <div class="card-body m-0 p-0">
          <div class="row align-items-center justify-content-center col-12 p-0 m-0">
            <div class="col-sm-12 col-lg-4 p-2 m-0 justify-content-center">
              <b-button-group :class="'mx-auto w-100'">
                <b-button
                    :class="(counters.type === 'day') ? 'active' : ''"
                    variant="outline-primary"
                    v-on:click="eventChangeSwitch('day')"
                >
                  {{ $t('pages.dashboard.period.day') }}
                </b-button>
                <b-button
                    :class="(counters.type === 'month') ? 'active' : ''"
                    variant="outline-primary"
                    v-on:click="eventChangeSwitch('month')"
                >
                  {{ $t('pages.dashboard.period.month') }}
                </b-button>
                <b-button
                    :class="(counters.type === 'year') ? 'active' : ''"
                    variant="outline-primary"
                    v-on:click="eventChangeSwitch('year')"
                >
                  {{ $t('pages.dashboard.period.year') }}
                </b-button>
              </b-button-group>
              <div class="col-12 m-0 p-0 mt-4">
                <form @submit.prevent="initSubmitCountersPeriod" @click="counters.type = ''">
                  <div class="d-flex">
                    <div class="form-group w-50">
                      <label for="start-date">{{ $t('common.startDate') }}</label>
                      <Datepicker
                          input-class="w-100"
                          id="start-date"
                          v-model="counters.period.startDate"
                          :format="datepicker.formatDate"
                      ></Datepicker>
                    </div>

                    <div class="form-group w-50 ml-4">
                      <label for="end-date">{{ $t('common.endDate') }}</label>
                      <Datepicker
                          input-class="w-100"
                          id="end-date"
                          v-model="counters.period.endDate"
                          :format="datepicker.formatDate"
                      ></Datepicker>
                    </div>

                    <div class="mt-4">
                      <input type="submit" class="btn btn-sm btn-primary ml-2 mt-2" :value="$t('common.validate')" />
                    </div>
                    <div class="ml-2 mt-4">
                      <button class="btn btn-warning btn-sm mt-2" v-on:click="refreshCounters">
                        <b-icon icon="arrow-counterclockwise" font-scale="1.2" variant="white"></b-icon>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-sm-12 col-lg-8 p-O m-0 justify-content-center p-2">
              <FormPeriod
                  :data="formPeriod.form"
                  :list-scenarios="ScenarioModule.scenarios"
                  :list-resellers="ResellerModule.resellers"
                  :list-prospect-resellers="ResellerModule.prospectResellers"
                  :display-scenario="true"
                  :is-reseller="false"
                  @submit="handleFormPeriod()"
                  @refresh="refreshApexChart"
                  :key="2"
              ></FormPeriod>
            </div>
          </div>
        </div>
      </div>
    </div>

    {{/* BLOCK COUNTERS */}}
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="card shadow title-box-order border-0">
          <div class="card-body">
            <b-icon class="float-right " icon="box-seam" font-scale="2" variant="primary"></b-icon>
            <div class="d-flex">
              <div class="w-50">
                <h6 class="text-uppercase mt-0">{{ $tc('common.order', 0).toUpperCase() }}</h6>
                <h2 class="my-2" id="counter-order">
                  <animated-number
                      :value="(DashboardModule.charts && DashboardModule.charts.periodCounters) ? DashboardModule.charts.periodCounters.countOrders : 0"
                      :formatValue="formatAnimatedNumber"
                      :duration="600"
                  />
                </h2>
              </div>
              <div class="w-50">
                <h6 class="text-uppercase mt-0">
                  {{ $t('pages.dashboard.countersCards.orderCard.orderCancel').toUpperCase() }}
                </h6>
                <h2 class="my-2" id="counter-order-canceled">
                  <animated-number
                      :value="(DashboardModule.charts && DashboardModule.charts.periodCounters) ? DashboardModule.charts.periodCounters.countCancelledOrders : 0"
                      :formatValue="formatAnimatedNumber"
                      :duration="600"
                  />
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="card shadow title-box-quote border-0">
          <div class="card-body">
            <b-icon class="float-right " icon="clipboard-check" font-scale="2" variant="success"></b-icon>
            <h6 class="text-uppercase mt-0">{{ $tc('common.quote', 0).toUpperCase() }}</h6>
            <h2 class="my-2" id="counter-quote">
              <animated-number
                  :value="(DashboardModule.charts && DashboardModule.charts.periodCounters) ? DashboardModule.charts.periodCounters.countQuotes : 0"
                  :formatValue="formatAnimatedNumber"
                  :duration="600"
              />
            </h2>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="card shadow title-box-reseller border-0">
          <div class="card-body">
            <b-icon class="float-right " icon="people" font-scale="2" variant="danger"></b-icon>
            <div class="d-flex">
              <div class="w-50">
                <h6 class="text-uppercase mt-0">
                  {{ $t('pages.dashboard.countersCards.resellerCard.created').toUpperCase() }}
                </h6>
                <h2 class="my-2" id="counter-reseller">
                  <animated-number
                      :value="DashboardModule.counters.countResellers"
                      :formatValue="formatAnimatedNumber"
                      :duration="600"
                  />
                </h2>
              </div>
              <div>
                <h6 class="text-uppercase mt-0">
                  {{ $t('pages.dashboard.countersCards.resellerCard.total').toUpperCase() }}
                </h6>
                <h2 class="my-2" id="counter-reseller-all">
                  <animated-number
                      :value="DashboardModule.counters.countAllResellers"
                      :formatValue="formatAnimatedNumber"
                      :duration="600"
                  />
                </h2>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="card shadow title-box-reseller border-0">
          <div class="card-body">
            <b-icon class="float-right " icon="graph-up" font-scale="2" variant="warning"></b-icon>
            <h6 class="text-uppercase mt-0">
              {{ $t('pages.dashboard.countersCards.salesCard.sales').toUpperCase() }}
            </h6>
            <h2 class="my-2" id="counter-sales">
              <animated-number
                  :value="(DashboardModule.charts && DashboardModule.charts.periodCounters) ? DashboardModule.charts.periodCounters.sales : 0"
                  :formatValue="formatAnimatedToPrice"
                  :duration="600"
              />
            </h2>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="card shadow title-box-reseller border-0">
          <div class="card-body">
            <b-icon class="float-right " icon="clipboard-data" font-scale="2" variant="dark"></b-icon>
            <h6 class="text-uppercase mt-0">
              {{ $t('pages.dashboard.countersCards.salesCard.conversionRate').toUpperCase() }}
            </h6>
            <h2 class="my-2" id="counter-conversion-rate">
              <animated-number
                  :value="(DashboardModule.charts && DashboardModule.charts.periodCounters) ? DashboardModule.charts.periodCounters.periodConversionRate : 0"
                  :formatValue="formatAnimatedToPercentage"
                  :duration="600"
              />
            </h2>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="card shadow title-box-reseller border-0">
          <div class="card-body">
            <b-icon class="float-right " icon="people-fill" font-scale="2" variant="danger"></b-icon>
            <h6 class="text-uppercase mt-0">
              {{ $t('pages.dashboard.countersCards.resellerCard.active').toUpperCase() }}
            </h6>
            <h2 class="my-2" id="counter-reseller-active">
              <animated-number
                  :value="DashboardModule.counters.countResellersActive"
                  :formatValue="formatAnimatedNumber"
                  :duration="600"
              />
            </h2>
          </div>
        </div>
      </div>
    </div>

    {{/* BLOCK GRAPHS */}}
    <div class="tabs-content" id="nav-tabContent">
      <b-card class="">
        <div class="card-header p-0">
          <div class="header-pages-block d-md-flex justify-content-between">
            <h3 class="p-0">
              <b-icon class="mr-3" icon="bar-chart" font-scale="1.5" variant="primary"></b-icon>
              {{ $t('pages.dashboard.graph.title') }}
            </h3>
          </div>
        </div>

        {{ /* BLOCK CHARTS */ }}
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12 col-xl-12">
            {{ /* CHARTS QUOTES / SCENARIO */ }}
            <div class="card border-0 card-chart card-quote mt-4">
              <div class="card-header d-flex justify-content-between">
                <div>
                  {{ $t('pages.dashboard.graph.charts.quoteScenario') + ', ' + $t('common.from') + ' ' }}
                  <span class="font-weight-bold quote-start-date" >
                    {{ formatDate(formPeriod.form.startDate) }}
                  </span>
                  {{ $t('common.at') }}
                  <span class="font-weight-bold quote-end-date" data-date="">
                    {{ formatDate(formPeriod.form.endDate) }}
                  </span>
                </div>
              </div>
              <div class="card-body">
                {{ /* APEX CHART */ }}
                <ApexChart
                    class="mt-5"
                    type="bar"
                    v-if="!formPeriod.form.reload"
                    ref="chartQuotesScenario"
                    height="400"
                    :options="apexChart.quoteScenario.options"
                    :series="apexChart.quoteScenario.series"
                    :key="4"
                ></ApexChart>
                <div class="text-center" v-else>
                  <b-spinner variant="success" label="Spinning"></b-spinner>
                  <div class="text-primary">{{ $t('common.loadingInProgress') }} ...</div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-12 col-lg-12 col-xl-12">
            {{ /* CHARTS ORDERS / SCENARIO */ }}
            <div class="card border-0 card-chart card-quote mt-4">
              <div class="card-header d-flex justify-content-between">
                <div>
                  {{ $t('pages.dashboard.graph.charts.orderScenario') + ', ' + $t('common.from') + ' ' }}
                  <span class="font-weight-bold quote-start-date" >
                    {{ formatDate(formPeriod.form.startDate) }}
                  </span>
                  {{ $t('common.at') }}
                  <span class="font-weight-bold quote-end-date" data-date="">
                    {{ formatDate(formPeriod.form.endDate) }}
                  </span>
                </div>
              </div>
              <div class="card-body">
                {{ /* APEX CHART */ }}
                <ApexChart
                    class="mt-5"
                    type="bar"
                    v-if="!formPeriod.form.reload"
                    ref="chartOrderScenario"
                    height="400"
                    :options="apexChart.orderScenario.options"
                    :series="apexChart.orderScenario.series"
                    :key="5"
                ></ApexChart>
                <div class="text-center" v-else>
                  <b-spinner variant="success" label="Spinning"></b-spinner>
                  <div class="text-primary">{{ $t('common.loadingInProgress') }} ...</div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-12 col-lg-12 col-xl-6">
            {{ /* CHARTS ORDERS / POSE */ }}
            <div class="card border-0 card-chart card-quote mt-4">
              <div class="card-header d-flex justify-content-between">
                <div>
                  {{ $t('pages.dashboard.graph.charts.pose') + ', ' + $t('common.from') + ' ' }}
                  <span class="font-weight-bold quote-start-date" >
                    {{ formatDate(formPeriod.form.startDate) }}
                  </span>
                  {{ $t('common.at') }}
                  <span class="font-weight-bold quote-end-date" data-date="">
                    {{ formatDate(formPeriod.form.endDate) }}
                  </span>
                </div>
              </div>
              <div class="card-body">
                {{ /* APEX CHART */ }}
                <ApexChart
                    class="mt-5" type="bar"
                    v-if="!formPeriod.form.reload"
                    ref="chartOrderPose"
                    height="400"
                    :options="apexChart.orderPose.options"
                    :series="apexChart.orderPose.series"
                    :key="6"
                ></ApexChart>
                <div class="text-center" v-else>
                  <b-spinner variant="success" label="Spinning"></b-spinner>
                  <div class="text-primary">{{ $t('common.loadingInProgress') }} ...</div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-12 col-lg-12 col-xl-6">
            {{ /* CHARTS RESELLER / SALES */ }}
            <div class="card border-0 card-chart card-quote mt-4">
              <div class="card-header d-flex justify-content-between">
                <div>
                  {{ $t('pages.dashboard.graph.charts.salesByReseller') + ', ' + $t('common.from') + ' ' }}
                  <span class="font-weight-bold quote-start-date" >
                    {{ formatDate(formPeriod.form.startDate) }}
                  </span>
                  {{ $t('common.at') }}
                  <span class="font-weight-bold quote-end-date" data-date="">
                    {{ formatDate(formPeriod.form.endDate) }}
                  </span>
                </div>
              </div>
              <div class="card-body">
                {{ /* APEX CHART */ }}
                <ApexChart
                    class="mt-5" type="bar"
                    v-if="!formPeriod.form.reload"
                    ref="chartOrderPose"
                    height="400"
                    :options="apexChart.resellerSales.options"
                    :series="apexChart.resellerSales.series"
                    :key="6"
                ></ApexChart>
                <div class="text-center" v-else>
                  <b-spinner variant="success" label="Spinning"></b-spinner>
                  <div class="text-primary">{{ $t('common.loadingInProgress') }} ...</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card>
      <b-card class="">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-6">
                <label for="monthlyAndCumulatedTurnoverChartYear">{{ $t('pages.dashboard.period.year') }} *</label>
                <Datepicker
                    input-class="w-100"
                    id="monthlyAndCumulatedTurnoverChartYear"
                    @input="getMonthlyAndCumulatedTurnoverChartData()"
                    v-model="monthlyAndCumulatedTurnoverChart.datepicker.selectedYear"
                    :format="monthlyAndCumulatedTurnoverChart.datepicker.format"
                    :type="monthlyAndCumulatedTurnoverChart.datepicker.type"
                    minimum-view="year"
                ></Datepicker>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="select-cat">{{ $tc('common.reseller', 0) }} *</label>

                  <select id="select-cat" class="form-control" v-model="monthlyAndCumulatedTurnoverChart.selectedReseller" @change="getMonthlyAndCumulatedTurnoverChartData()">
                    <option v-for="(reseller, index) in ResellerModule.resellers" :key="index" :value="reseller.id">
                      {{ reseller.company }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            {{ /* CHARTS RESELLER / SALES */ }}
            <div class="card border-0 card-chart card-quote mt-4">
              <div class="card-header d-flex justify-content-between">
                <div>
                  {{
                    $t('pages.dashboard.graph.charts.turnoverAndCumulatedTurnover') + ', '
                  }}
                  <span class="font-weight-bold quote-start-date" >
                    {{ formatDate(monthlyAndCumulatedTurnoverChart.datepicker.selectedYear, 'YYYY') }}
                  </span>
                </div>
              </div>
              <div class="card-body">
                {{ /* APEX CHART */ }}
                <ApexChart
                    class="mt-5" type="bar"
                    v-if="!formPeriod.form.reload"
                    ref="chartOrderPose"
                    height="400"
                    :options="apexChart.resellerMonthlyTurnover.options"
                    :series="[apexChart.resellerMonthlyTurnover.series[0], apexChart.resellerCumulatedTurnover.series[0]]"
                    :key="6"
                ></ApexChart>
                <div class="text-center" v-else>
                  <b-spinner variant="success" label="Spinning"></b-spinner>
                  <div class="text-primary">{{ $t('common.loadingInProgress') }} ...</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import moment from 'moment'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      tabs: {
        tabIndex: 0
      },
      months: [],
      datepicker: {
        formatDate: 'dd/MM/yyyy',
      },
      counters: {
        type: 'month',
        period: {
          startDate: null,
          endDate: null
        }
      },
      formPeriod: {
        form: {
          startDate: null,
          endDate: null,
          listScenarioId: [],
          listResellerId: [],
          listProspectResellerId: [],
          isSend: false,
          reload: false
        }
      },
      monthlyAndCumulatedTurnoverChart: {
        datepicker: {
          selectedYear: moment().startOf('year').format('YYYY'),
          type: "year",
          format: "yyyy"
        },
        selectedReseller: null

      },
      apexChart: {
        quoteScenario: {
          options: {},
          series: [],
        },
        orderScenario: {
          options: {},
          series: [],
        },
        orderPose: {
          options: {},
          series: [],
        },
        resellerSales: {
          options: {},
          series: []
        },
        resellerMonthlyTurnover: {
          options: {},
          series: [
            {
              data: [],
              name: ''
            }
          ]
        },
        resellerCumulatedTurnover: {
          options: {},
          series: [
            {
              data: [],
              name: ''
            }
          ]
        },
      }
    }
  },
  async created() {
    this.showLoader()

    // COUNTERS
    this.counters.period.startDate = moment().startOf('month').format('YYYY-MM-DD')
    this.counters.period.endDate = moment().format('YYYY-MM-') + moment().daysInMonth()

    this.$store.dispatch('fetchCounters', {
      resellerId: null,
      startDate : this.counters.period.startDate,
      endDate : this.counters.period.endDate
    })

    await this.$store.dispatch('fetchScenarios', {
      perPage: null,
      page: null
    })
      .then((response) => {
        response.forEach((scenario) => {
          this.formPeriod.form.listScenarioId.push(scenario.id)
        })
      })
    await this.$store.dispatch('fetchResellers', {
      perPage: null,
      page: null,
    })
      .then((response) => {
        response.forEach((reseller) => {
          this.formPeriod.form.listResellerId.push(reseller.id)
        })
      })
    await this.$store.dispatch('fetchProspectResellers', {
      perPage: null,
      page: null,
    })
      .then((response) => {
        response.forEach(() => {
          // this.formPeriod.form.listProspectResellerId.push(reseller.id)
        })
      })
    

    // Date form
    this.formPeriod.form.startDate = moment().startOf('month').format('YYYY-MM-DD')
    this.formPeriod.form.endDate = moment().format('YYYY-MM-') + moment().daysInMonth()

    for (let month = 0; month < 12; month++) {
      const formattedMonth = moment().month(month).format("MMM");
      this.months.push(formattedMonth);
    }
    // Call charts for all data
    this.handleFormPeriod()
  },
  updated() {
    this.hideLoader()
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    getMonthlyAndCumulatedTurnoverChartData() {
      if (this.monthlyAndCumulatedTurnoverChart.datepicker.selectedYear && this.monthlyAndCumulatedTurnoverChart.selectedReseller) {
        this.$store.dispatch('fetchCumulatedTurnoverChart', {
          startDate: moment(this.monthlyAndCumulatedTurnoverChart.datepicker.selectedYear, 'YYYY').startOf('year'),
          endDate: moment(this.monthlyAndCumulatedTurnoverChart.datepicker.selectedYear, 'YYYY').endOf('year'),
          listResellerId: [this.monthlyAndCumulatedTurnoverChart.selectedReseller]
        }).then(() => {
          this.apexChart.resellerMonthlyTurnover.options = this.setResellerMonthlyAndCumulatedTurnoverOptionsComputed
          this.apexChart.resellerCumulatedTurnover.options = this.setResellerMonthlyAndCumulatedTurnoverOptionsComputed
          this.apexChart.resellerMonthlyTurnover.series = this.setResellerMonthlyTurnoverSeriesComputed
          this.apexChart.resellerCumulatedTurnover.series = this.setResellerCumulatedTurnoverSeriesComputed
        })
      }
    },
    /*----------------- FORMAT ANIMATED NUMBER ------------------*/
    formatAnimatedNumber(value) {
      return `${value.toFixed(0)}`;
    },
    formatAnimatedToFloat(value) {
      return `${value.toFixed(0)}`;
    },
    formatAnimatedToPrice(value) {
      return `${value.toFixed(0)} €`;
    },
    formatAnimatedToPercentage(value) {
      return `${value.toFixed(2)} %`;
    },
    /*----------------- DATE ------------------*/
    formatDate(date, format = null)
    {
      return moment(date).format(format ?? 'DD/MM/YYYY')
    },
    /*----------------- TABS ------------------*/
    handleTabIndex(index) {
      this.tabs.tabIndex = index
    },
    refreshCounters() {
      this.showLoader();
      this.counters.period.startDate = moment().format('YYYY-MM-01')
      this.counters.period.endDate = moment().format('YYYY-MM-') + moment().daysInMonth()

      this.$store.dispatch('fetchCounters', {
        resellerId: null,
        startDate : moment(this.counters.period.startDate).format('YYYY-MM-DD'),
        endDate : moment(this.counters.period.endDate).format('YYYY-MM-DD')
      }).finally(() => {
        this.hideLoader();
      })
    },
    /*----------------- SWITCH PERIOD COUNTERS ------------------*/
    eventChangeSwitch(type) {
      this.showLoader();
      this.counters.type = type

      let startDate = null
      let endDate = null
      if(type === 'day') {
        startDate = moment().format('YYYY-MM-DD')
        endDate = moment().format('YYYY-MM-DD')
      } else if (type === 'month') {
        startDate = moment().format('YYYY-MM-01')
        endDate = moment().format('YYYY-MM-') + moment().daysInMonth()
      } else if (type === 'year') {
        startDate = moment().format('YYYY-01-01')
        endDate = moment().format('YYYY-12-') + moment().daysInMonth()
      }

      if(type !== 'period') {
        this.$store.dispatch('fetchCounters', {
          resellerId: null,
          startDate : startDate,
          endDate : endDate
        })
      }

      if (startDate && endDate) {
        this.formPeriod.form.startDate = startDate;
        this.formPeriod.form.endDate = endDate;
        this.counters.period.startDate = startDate
        this.counters.period.endDate = endDate
        this.handleFormPeriod()
      }
    },
    /*----------------- SUBMIT FORM COUNTER PERIOD ------------------*/
    initSubmitCountersPeriod() {
      this.showLoader()
      this.$store.dispatch('fetchCounters', {
        resellerId: null,
        startDate : moment(this.counters.period.startDate).format('YYYY-MM-DD'),
        endDate : moment(this.counters.period.endDate).format('YYYY-MM-DD')
      }).then(() => {
        if (this.counters.period.startDate && this.counters.period.endDate) {
          this.formPeriod.form.startDate = moment(this.counters.period.startDate).format('YYYY-MM-DD');
          this.formPeriod.form.endDate = moment(this.counters.period.endDate).format('YYYY-MM-DD');

          this.handleFormPeriod()
        }
      }).catch(() => {
        this.hideLoader()
      })
    },
    /*----------------- SUBMIT FORM PERIOD ------------------*/
    handleFormPeriod() {
      this.showLoader();
      // Var form
      this.formPeriod.form.reload = true
      this.formPeriod.form.startDate = moment(this.formPeriod.form.startDate).format('YYYY-MM-DD')
      this.formPeriod.form.endDate = moment(this.formPeriod.form.endDate).format('YYYY-MM-DD')

      // let globalResellersIds = [this.formPeriod.form.listResellerId, ...this.formPeriod.form.listProspectResellerId]
      // // Merge all resellers for single research
      // if (this.formPeriod.form.listProspectResellerId && this.formPeriod.form.listResellerId) {
      //   this.formPeriod.form.listResellerId = globalResellersIds
      // }
      this.$store.dispatch('fetchCharts', this.formPeriod.form)
        .then(() => {
          // QUOTE / SCENARIO (SERIES)
          this.apexChart.quoteScenario.series = this.setQuoteScenarioSeriesComputed
          // QUOTE / SCENARIO (LABEL)
          this.apexChart.quoteScenario.options = this.setQuoteScenarioOptionsComputed
          // ORDER / SCENARIO (SERIES)
          this.apexChart.orderScenario.series = this.setOrderScenarioSeriesComputed
          // ORDER / SCENARIO (OPTIONS)
          this.apexChart.orderScenario.options = this.setOrderScenarioOptionsComputed
          // ORDER / POSE (SERIES)
          this.apexChart.orderScenario.series = this.setOrderPoseSeriesComputed
          // ORDER / POSE (OPTIONS)
          this.apexChart.orderScenario.options = this.setOrderPoseOptionsComputed
          // RESELLER / SALES (SERIES)
          this.apexChart.resellerSales.series = this.setResellerSalesSeriesComputed
          // RESELLER / SALES (OPTIONS)
          this.apexChart.resellerSales.options = this.setResellerSalesOptionsComputed

          this.formPeriod.form.reload = false
        }).finally(() => {
        this.hideLoader();
      })
    },
    /*----------------- CHARTS ------------------*/
    getColorByType()
    {
      return {
        'quotes-scenario'  : "rgb(135, 206, 250)",
      }
    },
    getTranslateType()
    {
      return {
        'quotes-scenario' : {
          'en' : this.$tc('common.quote', 1) + ' / ' + this.$tc('common.scenario', 1),
          'fr' : this.$tc('common.quote', 1) + ' / ' + this.$tc('common.scenario', 1),
          'es' : this.$tc('common.quote', 1) + ' / ' + this.$tc('common.scenario', 1)
        },
        'orders-scenario' : {
          'en' : this.$tc('common.order', 1) + ' / ' + this.$tc('common.scenario', 1),
          'fr' : this.$tc('common.order', 1) + ' / ' + this.$tc('common.scenario', 1),
          'es' : this.$tc('common.order', 1) + ' / ' + this.$tc('common.scenario', 1)
        },
        'orders-pose' : {
          'en' : this.$tc('common.pose', 1),
          'fr' : this.$tc('common.pose', 1),
          'es' : this.$tc('common.pose', 1)
        },
      }
    },
    setConfigTooltip(type) {
      let that = this

      return {
        shared: true,
        intersect: false,
        y: {
          formatter: function (value) {
              return  value + " " + that.getTranslateType()[type]['fr'];
          }
        }
      }
    },
    async refreshApexChart() {
      this.showLoader()
      // Reset Date
      this.formPeriod.form.startDate = moment().format('YYYY-MM-01')
      this.formPeriod.form.endDate = moment().format('YYYY-MM-') + moment().daysInMonth()
      this.formPeriod.form.isSend = false
      // Reset list
      this.formPeriod.form.listScenarioId = []
      this.formPeriod.form.listResellerId = []
      this.formPeriod.form.listProspectResellerId = []

      await this.$store.dispatch('fetchScenarios', {
        perPage: null,
        page: null
      })
        .then((response) => {
          response.forEach((scenario) => {
            this.formPeriod.form.listScenarioId.push(scenario.id)
          })
        })
      await this.$store.dispatch('fetchResellers', {
        perPage: null,
        page: null
      })
        .then((response) => {
          response.forEach((reseller) => {
            this.formPeriod.form.listResellerId.push(reseller.id)
          })
        })
      await this.$store.dispatch('fetchProspectResellers', {
        perPage: null,
        page: null
      })
        .then((response) => {
          response.forEach(() => {
            // this.formPeriod.form.listProspectResellerId.push(reseller.id)
          })
        })

      // Call charts for all data
      this.handleFormPeriod()
    },
    truncateString(string, limit) {
      if (string && (typeof string === 'string' || string instanceof String)) {
        if (string.length <= limit) {
          return string
        }
        return string.slice(0, limit) + "...";
      }
      return ''
    }
  },
  watch: {
    setQuoteScenarioSeriesComputed: function(newValue)
    {
      this.apexChart.quoteScenario.series = newValue
    },
    setQuoteScenarioOptionsComputed: function(newValue)
    {
      this.apexChart.quoteScenario.options = newValue
    },
    setOrderScenarioSeriesComputed: function(newValue)
    {
      this.apexChart.orderScenario.series = newValue
    },
    setOrderScenarioOptionsComputed: function(newValue)
    {
      this.apexChart.orderScenario.options = newValue
    },
    setOrderPoseOptionsComputed: function(newValue)
    {
      this.apexChart.orderPose.options = newValue
    },
    setOrderPoseSeriesComputed: function(newValue)
    {
      this.apexChart.orderPose.series = newValue
    },
    setResellerSalesOptionsComputed: function(newValue)
    {
      this.apexChart.resellerSales.options = newValue
    },
    setResellerSalesSeriesComputed: function(newValue)
    {
      this.apexChart.resellerSales.series = newValue
    },
    setResellerMonthlyTurnoverSeriesComputed: function(newValue)
    {
      this.apexChart.resellerMonthlyTurnover.series = newValue
    },
    setResellerCumulatedTurnoverSeriesComputed: function(newValue)
    {
      this.apexChart.resellerCumulatedTurnover.series = newValue
    },
  },
  computed: {
    setQuoteScenarioSeriesComputed() {
      return [{
        name: this.$t('pages.dashboard.graph.charts.quoteScenario'),
        data: (this.DashboardModule.charts.chartQuoteScenarioModel.formatValue.series) ? this.DashboardModule.charts.chartQuoteScenarioModel.formatValue.series : []
      }]
    },
    setQuoteScenarioOptionsComputed() {
      return {
        chart: {
          type: 'bar',
          height: 700
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top'
            },
          }
        },
        tooltip: {
          enabled: true,
          x: {
            formatter: (val) => {
              return val
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: (val) => {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        xaxis: {
          categories: this.DashboardModule.charts.chartQuoteScenarioModel.formatValue.label ? this.DashboardModule.charts.chartQuoteScenarioModel.formatValue.label : [],
          labels: {
            enabled: true,
            formatter: (val) => {
              return this.truncateString(val, 15)
            },
            rotate: -90
          },
          position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          }
        },
        yaxis: {
          title: {
            text: this.$t('common.quantity')
          },
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val;
            }
          }
        },
        title: {
          text: this.$t('pages.dashboard.graph.charts.quoteScenario'),
          floating: true,
          offsetY: -20,
          align: 'center',
          style: {
            color: '#444'
          }
        }
      }
    },
    setOrderScenarioSeriesComputed() {
      return [{
        name: this.$t('pages.dashboard.graph.charts.orderScenario'),
        data: (this.DashboardModule.charts.chartOrderScenarioModel && this.DashboardModule.charts.chartOrderScenarioModel.formatValue.series)
            ? this.DashboardModule.charts.chartOrderScenarioModel.formatValue.series
            : []
      }]
    },
    setOrderScenarioOptionsComputed() {
      return {
        chart: {
          type: 'bar',
          height: 700
        },
        tooltip: {
          enabled: true,
          x: {
            formatter: (val) => {
              return val
            }
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top'
            },
          }
        },
        colors: [
          '#13d8aa',
        ],
        dataLabels: {
          enabled: true,
          formatter: (val) => {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: [
              '#13d8aa'
            ]
          }
        },
        xaxis: {
          categories: this.DashboardModule.charts.chartOrderScenarioModel.formatValue.label ? this.DashboardModule.charts.chartOrderScenarioModel.formatValue.label : [],
          labels: {
            enabled: true,
            formatter: (val) => {
              return this.truncateString(val, 15)
            },
            rotate: -60
          },
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          title: {
            text: this.$t('common.quantity')
          },
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val;
            }
          }
        },
        title: {
          text: this.$t('pages.dashboard.graph.charts.orderScenario'),
          floating: true,
          offsetY: -20,
          align: 'center',
          style: {
            color: '#444'
          }
        }
      }
    },
    setOrderPoseSeriesComputed() {
      return [{
        name: this.$t('pages.dashboard.graph.charts.pose'),
        data: (this.DashboardModule.charts.chartOrderPoseModel && this.DashboardModule.charts.chartOrderPoseModel.formatValue.series)
            ? this.DashboardModule.charts.chartOrderPoseModel.formatValue.series
            : []
      }]
    },
    setOrderPoseOptionsComputed() {
      return {
        chart: {
          type: 'bar',
          height: 700
        },
        tooltip: {
          enabled: true,
          x: {
            formatter: (val) => {
              return val
            }
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top'
            },
          }
        },
        colors: [
          '#f48024',
        ],
        dataLabels: {
          enabled: true,
          formatter: (val) => {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: [
              '#f48024'
            ]
          }
        },
        xaxis: {
          categories: this.DashboardModule.charts.chartOrderPoseModel.formatValue.label ? this.DashboardModule.charts.chartOrderPoseModel.formatValue.label : [],
          labels: {
            enabled: true,
            formatter: (val) => {
              return this.truncateString(val, 15)
            },
            rotate: -60
          },
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          title: {
            text: this.$t('common.quantity')
          },
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val;
            }
          }
        },
        title: {
          text: this.$t('pages.dashboard.graph.charts.pose'),
          floating: true,
          offsetY: -20,
          align: 'center',
          style: {
            color: '#444'
          }
        }
      }
    },
    setResellerSalesSeriesComputed() {
      return [{
        name: this.$t('pages.dashboard.graph.charts.salesByReseller'),
        data: (this.DashboardModule.charts.chartResellerSales && this.DashboardModule.charts.chartResellerSales.formatValue.series)
            ? this.DashboardModule.charts.chartResellerSales.formatValue.series
            : []
      }]
    },
    setResellerSalesOptionsComputed() {
      return {
        chart: {
          type: 'bar',
          height: 700
        },
        tooltip: {
          enabled: true,
          custom: ({ dataPointIndex, w }) => {
            const commandes = this.DashboardModule.charts.chartResellerSales.formatValue.ordersTotalCount[dataPointIndex];

            return `
              <div style="padding:10px; background:#fff; border-radius:5px; box-shadow: 0 0 5px rgba(0,0,0,0.2);">
                <strong>${w.globals.labels[dataPointIndex]}</strong><br>
                <span style="color:#69d2e7;">📦 Nombre de commandes : <strong>${commandes}</strong></span>
              </div>
            `;
          }
          // x: {
          //   formatter: (val) => {
          //     // return val
          //   }
          // },
          // y: {
          //   formatter: (value, { dataPointIndex }) => {
          //     return this.DashboardModule.charts.chartResellerSales.formatValue.ordersTotalCount[dataPointIndex] + ' commandes';
          //   }
          // }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "80%",
            dataLabels: {
              position: 'top'
            },
          }
        },
        colors: [
          '#69d2e7',
        ],
        dataLabels: {
          enabled: true,
          formatter:  (
            value
            //  { dataPointIndex }
            ) => {
            return "CA:" + value + "€"
            // return this.DashboardModule.charts.chartResellerSales.formatValue.ordersTotalCount[dataPointIndex] + ' commandes'
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: [
              '#69d2e7'
            ]
          }
        },
        xaxis: {
          categories: this.DashboardModule.charts.chartResellerSales.formatValue.label ? this.DashboardModule.charts.chartResellerSales.formatValue.label : [],
          labels: {
            enabled: true,
            formatter: (val) => {
              return this.truncateString(val, 15)
            },
            rotate: -60
          },
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          title: {
            text: this.$t('common.quantity')
          },
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val;
            }
          }
        },
        title: {
          text: this.$t('pages.dashboard.graph.charts.salesByReseller'),
          floating: true,
          offsetY: -20,
          align: 'center',
          style: {
            color: '#444'
          }
        }
      }
    },
    setResellerMonthlyTurnoverSeriesComputed() {
      return [{
        name: 'CA mensuel',
        data: (this.DashboardModule.cumulatedTurnoverChart && this.DashboardModule.cumulatedTurnoverChart.chartResellerTurnoverAndCumulatedTurnoverModel && this.DashboardModule.cumulatedTurnoverChart.chartResellerTurnoverAndCumulatedTurnoverModel.formatValue.series)
            ? this.DashboardModule.cumulatedTurnoverChart.chartResellerTurnoverAndCumulatedTurnoverModel.formatValue.series
            : []
      }]
    },
    setResellerCumulatedTurnoverSeriesComputed() {
      return [{
        name: 'CA cumulé',
        data: (this.DashboardModule.cumulatedTurnoverChart && this.DashboardModule.cumulatedTurnoverChart.chartResellerTurnoverAndCumulatedTurnoverModel && this.DashboardModule.cumulatedTurnoverChart.chartResellerTurnoverAndCumulatedTurnoverModel.formatValue.cumulatedTurnover)
            ? this.DashboardModule.cumulatedTurnoverChart.chartResellerTurnoverAndCumulatedTurnoverModel.formatValue.cumulatedTurnover
            : []
      }]
    },
    setResellerMonthlyAndCumulatedTurnoverOptionsComputed() {
      return {
        chart: {
          type: 'bar',
          height: 700
        },
        tooltip: {
          enabled: true,
          x: {
            formatter: (val) => {
              return val
            }
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "80%",
            dataLabels: {
              position: 'top'
            },
          }
        },
        colors: [
          '#b84644', '#4576b5',
        ],
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: '12px',
            transform: 'rotate(-90deg)',
            transformOrigin: 'center center',
            colors: [
              '#b84644', '#4576b5',
            ]
          }
        },
        xaxis: {
          categories: this.months,
          labels: {
            enabled: true,
            formatter: (val) => {
              return this.truncateString(val, 15)
            },
            rotate: -60
          },
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          title: {
            text: this.$t('common.quantity')
          },
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val;
            }
          }
        },
        title: {
          text: this.$t('pages.dashboard.graph.charts.turnoverAndCumulatedTurnover'),
          floating: true,
          offsetY: -20,
          align: 'center',
          style: {
            color: '#444'
          }
        }
      }
    },
    ...mapState(['DashboardModule']),
    ...mapState(['ResellerModule']),
    ...mapState(['ScenarioModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
    AnimatedNumber: () => import('animated-number-vue'),
    ApexChart: () => import('vue-apexcharts'),
    FormPeriod: () => import('@/components/pages/dashboard/FormPeriod'),
    Datepicker: () => import('vuejs-datepicker')
  }
}
</script>
